<template>
    <div v-loading.fullscreen.lock="loading">
        <common-content-search-bar-component
            add_auth="新增公共考核内容"
            :AllTitle="AllTitle"
            @search="get_common_content_index"
            @addTeam="addTeam"
        ></common-content-search-bar-component>
        <common-table-component
            details_auth="公共考核内容详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_common_content"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_common_content_index"
        ></common-page-component>
        <common-content-edit-component
            edit_auth="修改公共考核内容"
            del_auth="删除公共考核内容"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :common_content_details_data="fcommon_content_details_data"
            :AllTitle="AllTitle"
            :AllRank="AllRank"
            @exitDialog="dialogExit"
            @search="get_common_content_index"
            @show_edit="show_edit"
            @details_row="details_common_content"
        ></common-content-edit-component>
    </div>
</template>

<script>
import { common_content_index_request,common_content_details_request } from '@/network/kpi/CommonContent.js'
import { kpi_common_title_list_request } from '@/network/list'

import CommonContentSearchBarComponent from '@/components/kpi/CommonContent/CommonContentSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CommonContentEditComponent from '@/components/kpi/CommonContent/CommonContentEditComponent'

export default {
    name: 'CommonContentView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                with_title_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'with_title_name',
                    label: '所属标题',
                    minWidth: '200px',
                },{
                    prop: 'rank',
                    label: '项数',
                    minWidth: '100px',
                    editdata: (row) => {
                        return this.AllRank.filter(v=>v.id===row['rank'])[0].name
                    }
                },{
                    prop: 'content',
                    label: '考核内容',
                    minWidth: '500px',
                    show_overflow_tooltip: true
                },{
                    prop: 'create_time',
                    label: '创建时间',
                    minWidth: '160px',
                }
            ],
            fcommon_content_details_data: {},
            AllTitle: [],
            AllRank:[{id:1,name:'第一项'},{id:2,name:'第二项'},{id:3,name:'第三项'},{id:4,name:'第四项'},{id:5,name:'第五项'}]
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_common_content_index(param = {}) {
            this.loading = true
            this.cond.with_title_id = param.with_title_id ?? this.cond.with_title_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            common_content_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_common_content(id) {
            this.fid = id
            this.fdialogFormVisible = true
            common_content_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fcommon_content_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_title() {
            kpi_common_title_list_request()
                .then((s) => {
                    this.AllTitle = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_common_content_index()
        this.get_all_title()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonContentSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CommonContentEditComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>