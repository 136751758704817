import request from '../request'

//公共考核内容列表
export const common_content_index_request = p => {
    return request({
        method:'GET',
        url:'common_content/index',
        params: p
    })
}

//新增公共考核内容
export const common_content_add_request = d => {
    return request({
        method:'POST',
        url:'common_content/add',
        data: d
    })
}

//删除公共考核内容
export const common_content_del_request = id => {
    return request({
        method:'DELETE',
        url:'common_content/del',
        data: {
            id: id
        }
    })
}

//修改公共考核内容
export const common_content_edit_request = d => {
    return request({
        method:'PUT',
        url:'common_content/edit',
        data: d
    })
}

//公共考核内容详情
export const common_content_details_request = id => {
    return request({
        method:'GET',
        url:'common_content/details',
        params: {
            id
        }
    })
}