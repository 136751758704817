<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="EditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="所属标题" prop="with_title_id">
                            <el-select
                                v-model="form.with_title_id"
                                filterable
                                placeholder="选择标题"
                                class="with_title_idc"
                                clearable
                            >
                                <el-option
                                    v-for="title in AllTitle"
                                    :key="title.id"
                                    :label="title.title"
                                    :value="title.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="项数" prop="rank">
                            <el-select
                                v-model="form.rank"
                                filterable
                                placeholder="选择项数"
                                class="rankc"
                                clearable
                            >
                                <el-option
                                    v-for="rank in AllRank"
                                    :key="rank.id"
                                    :label="rank.name"
                                    :value="rank.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="考核内容" prop="note">
                            <el-input type="textarea" :rows="5" v-model="form.content"></el-input>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属标题</span>
                                </template>
                                <span>{{form.with_title_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>项数</span>
                                </template>
                                <span>{{rank_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <span>考核内容</span>
                                </template>
                                <span>{{form.content}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="CommonContentDel('EditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('EditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('EditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { common_content_add_request,common_content_del_request,common_content_edit_request } from '@/network/kpi/CommonContent.js'

export default {
    name: 'CommonContentEditComponent',
    data() {
        return {
            login_loading: false,
            form: {
                with_title_id: '',
                rank: '',
                content: ''
            },
            rules: {
                with_title_id: [
                    {
                        required: true,
                        message: '请选择关联标题',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请输入考核内容',
                        trigger: 'blur',
                    },
                ]
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '公共考核内容详情'
            } else if (this.show_type === 1) {
                return '添加公共考核内容'
            } else {
                return '修改公共考核内容'
            }
        },
        rank_name() {
            if(this.form.rank) {
                return this.AllRank.filter(v=>v.id===this.form.rank)[0].name
            } else {
                return ''
            }
        }
    },
    methods: {
        closeDialog() {
            this.resetForm()
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CommonContentInsert(formName)
            })
        },
        CommonContentInsert(formName) {
            this.login_loading = true
            let d = this.form
            common_content_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.form = {
                with_title_id: '',
                content: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CommonContentEdit(formName)
            })
        },
        CommonContentEdit(formName) {
            this.login_loading = true
            this.form.id = this.id
            let d = this.form
            common_content_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.form.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CommonContentDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    common_content_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        common_content_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String,
        AllTitle: {
            type: Array,
            default() {
                return []
            },
        },
        AllRank: {
            type: Array,
            default() {
                return []
            },
        },
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        common_content_details_data(newVal) {
            this.form = newVal
        }
    },
}
</script>

<style lang='less'>
.with_title_idc,.rankc {
    width: 100%
}
</style>